import {LocalDate as LocalDate} from "@js-joda/core";
LocalDate.LocalDate;

let addIntForDate = (num) => num >= 10?num:'0'+num;

let tomorrow = LocalDate.now().plusDays(1);
tomorrow = tomorrow._year+'-'+addIntForDate(tomorrow._month)+'-'+addIntForDate(tomorrow._day);
class TimeRangeManager {

    getToday(){
        let today = LocalDate.now();
        today = today._year+'-'+addIntForDate(today._month)+'-'+addIntForDate(today._day);
        return { from : today,to : tomorrow}
    }
    getCustom(date , numberOfDays = 1){
        let fromDate = LocalDate.parse(date);
        fromDate = fromDate._year+'-'+addIntForDate(fromDate._month)+'-'+addIntForDate(fromDate._day);

        let toDate = LocalDate.parse(date).plusDays(numberOfDays);
        toDate = toDate._year+'-'+addIntForDate(toDate._month)+'-'+addIntForDate(toDate._day);
        return { from : fromDate,to : toDate}
    }

    getYesterday(){
        let today = LocalDate.now();
        today = today._year+'-'+addIntForDate(today._month)+'-'+addIntForDate(today._day);

        let yesterday = LocalDate.now().minusDays(1);
        yesterday = yesterday._year+'-'+addIntForDate(yesterday._month)+'-'+addIntForDate(yesterday._day);
        return { from : yesterday,to : today}
    }

    getLastWeek(){
        let startOfWeek = LocalDate.now().minusDays(7);
        startOfWeek = startOfWeek._year+'-'+addIntForDate(startOfWeek._month)+'-'+addIntForDate(startOfWeek._day)
        return { from : startOfWeek,to : tomorrow}
    }


    getLastMonth(){
        let firstDayOfLastMonth = LocalDate.now().minusMonths(1);
        firstDayOfLastMonth = firstDayOfLastMonth._year+'-'+addIntForDate(firstDayOfLastMonth._month)+'-01'

        let now = LocalDate.now();
        let firstDayOfThisMonth = now._year + '-' + addIntForDate(now._month) + '-01';

        return { from : firstDayOfLastMonth,to : firstDayOfThisMonth}
    }

    getThisMonth(){
        let now = LocalDate.now();
        let firstDayOfMonth = now._year + '-' + addIntForDate(now._month) + '-01';
        return { from : firstDayOfMonth,to : tomorrow}
    }

    getThisYear(){
        let startOfYear = LocalDate.now()._year+'-01-01'
        return { from : startOfYear,to : tomorrow}
    }


}
export default TimeRangeManager = new TimeRangeManager();
