import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'cooking_instruction',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getCookingInstruction: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi + `cookinginstruction/${store.StoreDB}/${payload}`)
                .then(({data}) =>  data)
                .catch((err) => errorHandler.tomcatError(err))
        }
    }
}
