import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const Device = {
    getDeviceId: async () => {
        let id = localStorage.getItem('deviceRegistrationID');
        console.log(id)
        if (id && id !== "undefined") {
            return id
        }
        try {
            id = await Device.getFingerPrintID();
        } catch (e) {
            id = generateUUID();
        }
        console.log(`Device ID : ${id}`)
        localStorage.setItem('deviceRegistrationID', id);
        return id;
    },

    getFingerPrintID() {
        const fpPromise = FingerprintJS.load();
        return fpPromise
            .then(fp => fp.get())
            .then(result => {
                return result.visitorId;
            })
    },

    getPCName: (android) => {
        if (android) return Device.getDeviceId();
        return axios.get(hardwareApi + `utils/getPcName`)
            .then(({data}) => data)
            .catch(err => errorHandler.tomcatError(err, false, `Cannot get Device Name`));
    },
}
