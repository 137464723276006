import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'billing',
    state: {
        daysToExpire: 30
    },
    getters: {
        getDaysToExpire: (state) => state.daysToExpire,
    },
    mutations: {
        setDaysToExpire: (state, data) => state.daysToExpire = data,
    },
    actions: {
        getExpiryDateByCustomerID: async (context) => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getExpiryDate?customer=` + customerID
                }
            }).then(({data}) => {
                let date1 = new Date();
                let date2 = new Date(data);
                let diffDays = parseInt(((date2 - date1) / (1000 * 60 * 60 * 24)).toString(), 10);
                context.commit(`setDaysToExpire`, diffDays);
                if (diffDays <= 0) {
                    context.commit(`setDaysToExpire`, 0);
                    return Swal.fire({
                        title: `System Expired ! `,
                        icon: `error`,
                        html: `<p>Please make payment to continue</p>`,
                        confirmButtonText: `Continue`,
                    }).then(() => {
                        try {
                            router.push({name: `system-expired`})
                        } catch (e) {
                        }
                    });
                }
            }).catch(err => errorHandler.tomcatError(err))
        },

        getCustomerBalance: async () => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getBalance?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        hasOverdueCount: async (context) => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/hasOverdueCount?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getDueInvoices: async () => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getDueInvoice?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getActiveStores: async () => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getActiveStores?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getCustomerQuotes: async (context) => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getQuotes?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        makeKazangLicensePayment: async (context, payload) => {
            let invoiceReference = payload.invoiceReference;
            return axios.post(kazangApi + `paymentGateWay/makeAndProcessPayment`, payload, {timeout: 1000 * 2 * 60})
                .then(() => {
                    return axios.get(subscriptionApiGET, {
                        params: {
                            route: `paymentCompleted/kazangProcess/${invoiceReference}`
                        }
                    }).then(() => context.dispatch(`getExpiryDateByCustomerID`)).catch(err => {
                        throw new Error(err.response)
                    })
                }).catch(err => errorHandler.tomcatError(err));

        },

        makeVCDLicensePayment: async (context, invoiceReference) => {
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `paymentCompleted/kazangProcessVCD/${invoiceReference}`
                }
            }).then(() => context.dispatch(`getExpiryDateByCustomerID`)).catch(err => errorHandler.tomcatError(err));
        },

    }
}
