import {responses} from "@/utils/responses";
import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'stock',
    state: {
        stock: [],
        stockItem: {},
        stockCache: new Map()
    },
    getters: {
        getStockProducts: (state) => state.stock,
        getAllStockProducts: ({stockCache}) => [...stockCache.values()],
        getStockItem: (state) => state.stockItem,
        getByPlu: (state) => (plu) => state.stockCache.get(plu),
    },

    mutations: {
        setStock: async (state, payload) => {
            if (payload) {
                payload.sort((a, b) => a.Description.localeCompare(b.Description))
                await Storage.setStock(payload);
                state.stock = payload.filter(x => x.FastLine === 1)
                    .sort((a, b) => a.Description.localeCompare(b.Description));
                //state.allStock = payload
                state.stockCache = payload.reduce((mapAcc, obj) => {
                    mapAcc.set(obj.PLU, obj);
                    return mapAcc;
                }, new Map())

            } else {
                await Storage.getFastLine().then(res => {
                    state.stock = res.sort((a, b) => a.Description.localeCompare(b.Description))
                })
            }
        },
        setStockSetting: async (state, payload) => {
            if (payload) {
                await Storage.setStockSettings(payload);
            }
        },
        setSearch: (state, payload) => {
            state.stock = payload.sort((a, b) => a.Description.localeCompare(b.Description))
        },
        setStockItem: (state, payload) => {
            state.stockItem = payload
        },
        addStockItem: (state, payload) => {
            Storage.getAllStock().then(stock => {
                state.stock = state.stock.map(s => {
                    if (s.PLU === payload.PLU) {
                        return payload;
                    }
                    return s;
                })
                return Storage.setStock(state.stock);
            });
        }
    },
    actions: {
        addStockItem: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            try {
                delete payload.CreatedUser
            } catch (e) {
                console.error(e)
            }
            return axios.post(`${serverApi}stock/${store.StoreDB}`, payload)
                .then(({data}) => {
                    context.commit('addStockItem', data);
                    context.commit('setStockItem', data);
                    return data;
                })
                .catch((err) => errorHandler.tomcatError(err)).finally(() => context.dispatch('getAllStock'))
        },
        getStockSetting: (context, plu) => {
            return Storage.getStockSetting(plu)
        },
        getAllStock: async (context) => {
            let store = await Storage.getDefaultStore();
            let device = await Storage.getDeviceSettings();
            if (!store) return;
            let url = `https://realtime.abc.co.zm:8443/OnlineSalesServer/stock/${store.StoreDB}`;
            // let url = serverApi + `stock/${store.StoreDB}`;
            return axios.get(url, {params: {pc: device.PC}})
                .then(async ({data}) => {
                    const stockSettings = data.map(s => {
                        let set = s.StockSetting
                        if (set) {
                            return {
                                plu: s.PLU,
                                cookingLink: set.CookingLink,
                                mtv: set.mtv,
                                askForPrice: set.askForPrice,
                                askForDescription: set.askForDescription,
                                askForBoth: set.askForBoth,
                                NonStock: set.NonStock,
                            }
                        } else {
                            return {
                                plu: s.PLU,
                                mtv: 0.00,
                                NonStock: false,
                                cookingLink: false,
                                askForPrice: false,
                                askForDescription: false,
                                askForBoth: false,
                            }
                        }
                    })
                    const stock = data.map(s => {
                        delete s.CreatedDate
                        delete s.Image
                        delete s.Price1
                        delete s.Price2
                        delete s.Price3
                        delete s.Price4
                        delete s.Price5
                        delete s.args
                        delete s.where_between
                        delete s.StockSetting
                        return s;
                    })
                    await context.commit('setStock', stock);
                    await context.commit('setStockSetting', stockSettings);
                    context.dispatch(`setStockImages`)
                }).catch((err) => errorHandler.tomcatError(err))
        },
        searchStock: (context, query) => {
            let search = context.getters.getSearchCategories;
            query.category = search.category;
            query.subCategory = search.subCategory;
            query.sub2Category = search.sub2Category;

            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return Storage.searchStock(query).then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },
        searchDescription: (context, query) => {
            let search = context.getters.getSearchCategories;
            query.category = search.category;
            query.subCategory = search.subCategory;
            query.sub2Category = search.sub2Category;

            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return Storage.searchStockDesc(query).then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },

        searchDepartment: async (context, query) => {
            let search = context.getters.getSearchCategories;
            query.category = search.category;
            query.subCategory = search.subCategory;
            query.sub2Category = search.sub2Category;

            query.description = '';
            return Storage.searchStockDesc(query).then((value => value));
        },

        getFastLine: (context, query) => {
            context.commit(`setSearchCategories`, {
                category: ``,
                subCategory: ``,
                sub2Category: ``
            })
            return Storage.getFastLine().then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },

        setStockImages: async (context) => {
            let store = await Storage.getDefaultStore();
            let stockItems = await Storage.getAllStock();
            let images = [];
            let methods = stockItems.map(item => {
                return axios.get(franchiseSpringApi + `stockImage/${store.StoreDB}/getImage?PLU=` + item.PLU).then(({data}) => {
                    if (data) images.push({PLU: item.PLU, Image: data});
                });
            });
            return Promise.all(methods).then(async () => {
                await Storage.setStockImages(images);
                context.commit(`setStock`)
            })
        },
    }
}
