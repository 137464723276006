import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'email',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        sendInvoiceDetails : async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `emailrecord/${store.StoreDB}/emailSale`, payload)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getAllEmailAddresses: async () => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `emailrecord/${store.StoreDB}/getAllEmailAddresses`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        addEmailToStore: async (context,payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `emailrecord/${store.StoreDB}/addEmailToStore`,payload)
                .then(()=>context.dispatch(`getAllEmailAddresses`))
                .catch((err) => errorHandler.tomcatError(err))
        },

        removeEmailFromStore : async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.delete(franchiseTomcatApi + `emailrecord/${store.StoreDB}/removeEmailFromStore`, {
                params: payload
            }).then(() => context.dispatch(`getAllEmailAddresses`)).catch((err) => errorHandler.tomcatError(err))
        }
    }
}
