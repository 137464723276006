import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse, faBarcode, faMagnifyingGlass, faPlus, faMinus, faBars, faAngleRight,faAngleLeft, faArrowLeft, faDeleteLeft,faPercent,
    faCartShopping, faPaperPlane, faClose, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,faAddressCard,
    faReceipt, faGear, faXmark, faChevronRight, faArrowRightFromBracket, faCreditCard, faMoneyBill, faChevronUp,faCrown,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faUserPlus, faHandHoldingDollar, faCoins, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave,faChartLine,faUpload,faTruck,faCancel,
    faCommentsDollar,faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple,faHandHoldingDroplet,faEnvelope,faExchange,
    faArrowRight , faExclamationCircle,faBell ,faCodeMerge,faRightLeft , faBusinessTime,

} from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHouse, faBarcode, faMagnifyingGlass, faClose, faPlus, faMinus, faBars, faAngleRight, faArrowLeft,faCrown,
    faDeleteLeft, faCartShopping, faPaperPlane, faCashRegister, faBoxes, faLock, faBasketShopping, faBoxesStacked,
    faReceipt, faGear, faArrowRightFromBracket, faXmark, faChevronRight, faCreditCard, faMoneyBill, faChevronUp,faArrowRight,
    faArrowsRotate, faLayerGroup, faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus, faArrowRotateLeft,
    faGears, faMoneyBills, faArrowTrendDown, faPrint, faTrash,faTable,faSave, faArrowsRotate, faLayerGroup,faPercent,
    faPenToSquare, faUsers, faHandHoldingDollar, faCoins, faUserPlus,faChartLine,faUpload,faTruck,faCommentsDollar,
    faArrowsSplitUpAndLeft,faQuestionCircle,faChartSimple, faHandHoldingDroplet,faEnvelope,faExchange,faCancel,faAddressCard,
    faExclamationCircle , faBell,faCodeMerge,faRightLeft,faAngleLeft , faBusinessTime
)

