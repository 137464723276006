import {Storage} from "@/services/Storage"
import store from '../store'

export const authentication = {
    async isCashierLoggedIn() {
        let cashier = await Storage.getCurrentUser()
        await this.hasUsers()
        if (!!cashier) store.commit('setCurrentUser', cashier);
        return !!cashier;
    },

    hasUsers() {
        return Storage.getAllUsers().then(users => {
            if (!!users) {
                store.commit('setAllUsers', users);
            }
            return !!users;
        }).catch(() => false);
    },

    isAdminLoggedIn: async () => {
        let adminUser = await Storage.getAdminUser();
        return !!adminUser;
    },

    isStoreSelected() {
        return Storage.getDefaultStore().then((defaultStore) => {
            if (defaultStore) {
                store.commit('setDefaultStore', defaultStore)
            }
            return !!defaultStore;
        }).catch(() => false)
    },

    isFloatSet(user) {
        return Storage.getFloat().then(res => {
            let userFloat = res.find(f => f.user.toLowerCase() === user.toLowerCase());
            return !!userFloat
        }).catch(() => false)
    },

    async isCurrentUserAdmin() {
        let user = await Storage.getCurrentUser();
        return user.Type.toLowerCase() === 'admin';
    }
}
