<template>
  <div class="page">
    <div class=" header container-fluid">
      <div class="row">
        <h1>{{ greeting() }}</h1>
        <p v-if="curUser">{{ curUser }}</p>
      </div>
    </div>
    <div class="container d-flex justify-content-center">
      <div class="float-form">
        <FloatAssignForm/>
      </div>
    </div>
  </div>
</template>

<script>
import FloatAssignForm from "@/components/point-of-sale/float/FloatAssignForm";

export default {
  name: "FloatView",
  async beforeMount() {
    let isCashierLoggedIn = await auth.isCashierLoggedIn()
    if (!isCashierLoggedIn) return this.$router.push({name: 'setup'});
  },
  components: {FloatAssignForm},
  computed: {
    curUser() {
      let user = this.$store.getters.getCurrentUser
      if (!user) {
        return this.$store.commit('setCurrentUser')
      }
      return this.$store.getters.getCurrentUser.Username
    },
  },
  methods: {
    greeting() {
      const today = new Date();
      const currentHour = today.getHours()
      const currentMin = today.getMinutes()
      if (currentHour >= 0 && (currentHour <= 11 && currentMin <= 59)) {
        return 'Good Morning'
      } else if (currentHour >= 12 && (currentHour <= 17 && currentMin <= 59)) {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    }
  }
}
</script>

<style scoped>
.page {
  background: rgb(64, 67, 74);
  background: -moz-linear-gradient(180deg, rgba(64, 67, 74, 1) 14%, rgba(255, 255, 255, 1) 14%);
  background: -webkit-linear-gradient(180deg, rgba(64, 67, 74, 1) 14%, rgba(255, 255, 255, 1) 14%);
  background: linear-gradient(180deg, rgba(64, 67, 74, 1) 14%, rgba(255, 255, 255, 1) 14%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#40434a", endColorstr="#ffffff", GradientType=1);
}

.header {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: rgba(64, 67, 74, 1) 14%;
  margin-top: -2px !important;
  max-height: 280px;
}

p {
  font-weight: 400;
}
</style>
