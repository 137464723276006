<template>
  <div class="card shadow-lg rounded-3 py-3" style="border:none">
    <div class="card-body p-1">
      <h1 class="card-title mb-3 text-dark text-uppercase" style="font-weight:700"><span
          style="color: #0092f7">Log</span> In</h1>
      <div class="container mb-2">
        <div class="row d-flex justify-content-center">
          <label>
            <input class="input-amount" ref="passwordField" @keyup.enter="login" placeholder="Enter your PIN" :type="passwordFieldType" v-model="password">
          </label>

          <label class="show-password-checkbox">
            <input type="checkbox" v-model="show_password">
            Show PIN
          </label>

          <br><br>
          <div class="container button-pane">
            <div class="row text-center d-flex flex-column align-items-center">
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="inputNumber($event)" class="btn" type="button">1</button>
                <button @click="inputNumber($event)" class="btn" type="button">2</button>
                <button @click="inputNumber($event)" class="btn" type="button">3</button>
              </div>
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="inputNumber($event)" class="btn" type="button">4</button>
                <button @click="inputNumber($event)" class="btn" type="button">5</button>
                <button @click="inputNumber($event)" class="btn" type="button">6</button>
              </div>
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="inputNumber($event)" class="btn" type="button">7</button>
                <button @click="inputNumber($event)" class="btn" type="button">8</button>
                <button @click="inputNumber($event)" class="btn" type="button">9</button>
              </div>
              <div class="col-12 d-inline-flex justify-content-center">
                <button @click="addPoint" class="btn" type="button">
                  .
                </button>
                <button @click="inputNumber($event)" class="btn" type="button">0</button>
                <button @click="backspace" class="btn" type="button">
                  <font-awesome-icon icon="fa-solid fa-delete-left"/>
                </button>
              </div>
            </div>
          </div>

          <div class="text-center my-3">
            <button :disabled="loading" @click="login" type="submit" class="login-btn">
              <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Login
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {responses} from "@/utils/responses";
import {Storage} from "@/services/Storage";

export default {
  name: "PosLoginForm",
  data() {
    return {
      password: '',
      loading: false,
      show_password: false
    }
  },
  async beforeMount() {
    localStorage.removeItem('CapacitorStorage.current_user');
    let hasUsers = await auth.hasUsers()
    if (!hasUsers) return this.$router.push({name: 'setup'})
    await new Promise(resolve => setTimeout(resolve, 500));
    this.$refs.passwordField.focus();
  },
  computed: {
    passwordFieldType() {
      return this.show_password ? 'text' : 'password';
    },
  },
  methods: {
    login() {
      this.loading = true;
      return this.$store.dispatch(`posLogin`, this.password)
          .then((res) => {
            if (!res) return responses.throwErr(`Incorrect Password`)
            return this.$router.push({name: 'float'})
          })
          .finally(() => this.loading = false)
    },
    inputNumber(event) {
      let elem = event.target
      if (elem) {
        let txt = elem.textContent || elem.innerText;
        if (txt) this.password += txt;
      }
    },
    addPoint() {
      if (!this.password.includes('.') && this.password !== '0') {
        this.password += '.'
      }
    },
    backspace() {
      let password = this.password
      let l = password.length
      this.password = password.substring(0, l - 1);
      l = this.password.length
      if (l === 0) {
        this.password = ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.login-btn {
  background: #1e88e5;
  color: white;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 45px;
}

.btn {
  display: inline-block;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

button {
  border-radius: 10px !important;
  border: 1px solid #9e9e9e !important;
  height: 45px;
  width: 100px;
  outline: none;
  margin: 1px;
  box-shadow: -2px -2px 5px white, 3px 3px 5px rgb(0 0 0 / 10%);
}

.card {
  padding-left: 10px;
  padding-right: 10px;
  width: 400px;
  max-width: 95vw;
  margin-top: 3rem !important;
}

.show-password-checkbox {
  margin-bottom: 20px;
}

.input-amount {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 350px;
  height: 35px;
  border-radius: 10px;
  border: none;
  outline: transparent;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #363636;
}


</style>
