<template>
  <div class="product-card" @click="$emit('click')">
    <div class="stock-card_img mx-2 mt-2" v-if="itemImage">
      <img :src="itemImage" alt="Item Image">
    </div>
    <div class="card-body mx-3 p-1 d-flex justify-content-between flex-column">
      <h6 class="product-description">
        {{ description }} <br>
        <span v-if="showQty">
          <span v-if="stock.QTY > 0" class="badge bg-info">Qty in Stock : {{ $utils.formatQTY(stock.QTY)  }}</span>
          <span v-else class="badge bg-danger">Not in stock</span>
        </span><br>
        <span class="product-description2">{{ description2 }}</span>
      </h6>
      <h5 class="product-price">{{ $utils.formatMoney(stock.SellingIncl) }}</h5>
    </div>

  </div>
</template>

<script>
import {utils as $utils} from "@/utils/utils";

export default {
  name: "Product",
  props: {
    stock: {
      type: Object,
      required: true
    }
  },
  computed: {
    description() {
      return $utils.toPascalCase(this.stock.Description)
    },
    showQty() {
      let securities = this.$store.getters.getSecurities;
      if (!securities) return false;
      return !securities.hideQuantity;
    },
    description2() {
      return $utils.toPascalCase(this.stock.Description2)
    },
    itemImage() {
      return this.stock.Image
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      textLength: 50,
      image: require('/src/assets/img/no-image.png')
    }
  }, watch: {
    windowWidth(nh, oh) {
      if (nh < 400) {
        this.textLength = 25
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 80px;
}

h4, h5, h6 {
  margin: 0;
}

.example-card-img-responsive {
  width: 80px;
  height: 65px;
  margin: 10px;
  opacity: 0.8;
}


.product-card {
  height: 90px;
  transition: 200ms ease-in-out;
  overflow: hidden;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .product-description {
    font-weight: 600;
    color: #303030;
  }

  .product-description2 {
    font-weight: 400;
    color: #303030;
  }

  .product-price {
    font-weight: 700;
    color: #322f2f;
  }

  &:hover, &:focus {
    background: #0092f7;
    color: white;
    transform: scale(1.03);

    .product-description {
      color: white;
    }

    .product-price {
      color: white;
    }
  }
}

img {
  height: 60px;
  width: 60px;
  opacity: 0.7;
  border-radius: 10px;
}

</style>
