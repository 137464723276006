import axios from "axios"

export default {
    name: 'payment',
    state: {

    },
    getters: {

    },

    mutations: {

    },
    actions: {
        getStoreByID: (context,id) => {
            return axios.get(franchiseTomcatApi+`kazangSignUp/getStoreByID`,{
                params : { id : id }
            }).then(({data})=>data)
        },

        submitData(context,payload){
            return axios.post(`https://chrilantech.com/LytSoftPosOnline/api/email/sendKazangSignUp`,payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(()=>responses.showSuccess(`Request Submitted Successfully`))
        }
    }
}
