<template>
  <div class="banner">
    <KazangBanner/>
  </div>
</template>

<script>
import KazangBanner from "@/components/layout/utils/KazangBanner";
export default {
  name: "Header",
  components: {KazangBanner}
}
</script>

<style scoped>
.banner {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin-top: -2px !important;
  max-height: 280px;
  background-size: cover;
}
</style>