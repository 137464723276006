import axios from "axios";
import {Storage} from "@/services/Storage";

export default {
    name: 'payment-methods',
    state: {
        paymentMethods : [],
        has543 : false,
        hasKazang : true,
        hasOtherMethods : false
    },
    getters: {
        getPaymentMethods : (state) => state.paymentMethods,
        getHas543 : (state) => state.has543,
        getHasKazang : (state) => state.hasKazang,
        getHasOtherMethods : (state) => state.hasOtherMethods,
    },
    mutations: {
        setPaymentMethods : async (state , data) => {
            if (data){
                await Storage.setPaymentMethods(data);
            }else {
                data = await Storage.getPaymentMethods();
            }
            state.paymentMethods = data
        },

        setHas543 : async (state, data) => {
            if (!data) {
                data = false;
            }
            await Storage.setHas543(data)
            return state.has543 = data
        },

        setHasKazang : async (state, data) => {
            if (!data) {
                data = false;
            }
            await Storage.setHasKazang(data)
            return state.hasKazang = data
        },
    },
    actions: {
        getAllPaymentMethods : async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `payment_options/${store.StoreDB}/getAll`)
                .then(({data}) => context.commit('setPaymentMethods', data))
                .catch((err) => errorHandler.tomcatError(err))
        },
        createPaymentMethod : async (context, data) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            return axios.post(franchiseTomcatApi + `payment_options/${store.StoreDB}`, {
                name: data,
                user : user.Username
            }).then(({data}) => context.commit('setPaymentMethods', data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        editPaymentMethod : async (context, data) => {
            let store = await Storage.getDefaultStore();
            return axios.put(franchiseTomcatApi + `payment_options/${store.StoreDB}/${data.id}`, data)
                .then(({data}) => context.commit('setPaymentMethods', data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        deactivatePaymentMethod : async (context, data) => {
            let store = await Storage.getDefaultStore();
            return axios.put(franchiseTomcatApi + `payment_options/${store.StoreDB}/deactivate`, data)
                .then(() => context.dispatch('getAllPaymentMethods'))
                .catch((err) => errorHandler.tomcatError(err))
        },

        is543Integrated : async (context) => {
            return context.commit(`setHas543`,false);
        },

        isKazangIntegrated : (context) => {
            return context.commit(`setHasKazang`,true);
        },

        getKazangData: (context)=>{
            return axios.all([
                context.dispatch(`kazangLogin`),
                context.dispatch(`kazangProductList`),
            ]);
        }
    }
}
