<template>
  <div class="sales-panel container-fluid h-100 w-100">
    <div v-if="showHeader" class="container d-flex float-row align-items-center sale-header">
      <a class="btn fw-bold text-primary" @click="selectCustomer">Add Customer</a>
      <span class="badge bg-info m-2" v-if="tipAmount > 0">Tip : {{ $utils.formatMoney(tipAmount) }}</span>
    </div>

    <b class="m-2" v-if="customer">Customer : {{ customer }}</b>

    <div class="sale-lines-table">
      <ul class="sales-list">
        <li :key="index" v-for="(sale , index) in currentSaleLines">
          <CurrentSaleLine :sale-line="sale"/>
        </li>
      </ul>
    </div>
    <div class="sale-stats">
      <SaleDetails/>
    </div>
  </div>
</template>

<script>
import CurrentSaleLine from "@/components/point-of-sale/sales/CurrentSaleLine";
import SaleDetails from "@/components/point-of-sale/sales/SaleDetails";

export default {
  name: "CurrentSaleTable",
  components: {SaleDetails, CurrentSaleLine},
  props: {
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showBack: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    currentSale() {
      return this.$store.getters.getCurrentSale
    },
    tipAmount(){
      let tipAmount = this.$store.getters.getTipAmount;
      console.log(tipAmount)
      return tipAmount;
    },

    customer() {
      let id = this.$store.getters.getCustomer;
      return this.$store.getters.getDebtorByID(id);
    },
    currentSaleLines() {
      return this.$store.getters.getSaleLines
    }
  },
  methods: {
    selectCustomer(){
      return this.$router.push({ name : `select-customer` , query : { saleNumber : this.$route.query.saleNumber } })
    },
    close() {
      return this.$router.push({ name :'pos' })
    },

    printBill() {
      this.$utils.showLoading()
      return this.$store.dispatch(`printBillSlip` , this.$route.query.saleNumber)
          .finally(()=>this.$utils.hideLoading())
    },
  }
}
</script>

<style lang="scss" scoped>
.sales-panel {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  .sale-header {
    background: #F2F2F4;
    -webkit-box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.4);
    box-shadow: 0 2px 8px 0 rgba(203, 153, 126, 0.4);
    border: 1px solid #E6E7EC;
    height: 53px;
    max-height: 55px;

    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      h2 {
        margin: 0;
      }

      button {
        width: 30px;
        background: white;
        font-size: 25px;
      }
    }
  }

  @media (min-height: 769px) {
    .sale-lines-table {
      height: 83vh !important;
    }
  }

  .sale-lines-table {
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;
    height: 77vh;

    .sales-list {
      list-style: none;
      padding: 0 0 130px 0;

      li {
        border: 1px solid #E6E7EC;
      }
    }
  }

  .sale-stats {
    position: absolute;
    background: #F2F2F4;
    border: 1px solid #E6E7EC;
    max-height: 163px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
