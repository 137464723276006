import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'kazang',
    state: {
        kazangSessionID: null,
        kazangProductList: [],
    },
    getters: {
        getKazangSessionID: (state) => state.kazangSessionID,
        getKazangProviders: (state) => state.kazangProductList.map(item => item.Provider),
        getKazangProductListByProvider: (state) => (provider) => {
            return state.kazangProductList
                .find(item => item.Provider === provider)
        }
    },
    mutations: {
        setKazangTimeStampProperty: (state, payload) => state.kazangTimeStamp = payload,
        setKazangSession: async (state, data) => {
            if (data) {
                await Storage.setKazangSession(data);
            } else {
                data = await Storage.getKazangSession();
            }
            state.kazangSessionID = data
        },

        clearKazangSession: async (state) => {
            await Storage.setKazangSession(null);
            state.kazangSessionID = null;
        },

        setKazangProductList: async (state, data) => {
            if (data) {
                await Storage.setKazangProductList(data);
            } else {
                data = await Storage.getKazangProductList();
            }
            state.kazangProductList = data
        },
    },
    actions: {
        kazangLogin: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.post(kazangApi + `login/${store.StoreDB}`)
                .then(({data}) => context.commit(`setKazangSession`, data.sessionId))
                .catch(() => context.commit(`setHasKazang`, false));
        },
        kazangProductList: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(kazangApi + `products/${store.StoreDB}`)
                .then(({data}) => context.commit(`setKazangProductList`, data.productList))
                .catch((err) => errorHandler.tomcatError(err))
        },

        firstKazangPaymentStep: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let saleDetails = context.getters.getSaleDetails;
            let amount = Number(saleDetails.total) - Number(context.getters.getSaleDiscount.total);

            if (typeof payload === 'object') {
                return axios.post(kazangApi + `pay/${store.StoreDB}`, {
                    session: context.getters.getKazangSessionID,
                    amount: Number(payload.splitArray.Kazang.toFixed(2)),
                    cell: payload.customerContact
                }).then(({data}) => {
                    if (!data.secondStep && data.Response === '0') {
                        return context.dispatch('processKazangInvoice', {
                            amount: amount,
                            splitObj: payload,
                            SecondRefrence: data.SecondRefrence
                        })
                    } else if (data.Response === '3') {
                        return context.dispatch(`resetKazangUser`, data.Response);
                    } else if (data.Response === '41') {
                        return context.dispatch(`resetKazangUser`, data.Response);
                    } else if (data.Response !== '0') {
                        throw new Error(data.Print)
                    } else {
                        data.customerContact = payload.customerContact;
                        data.splitObj = payload;
                        context.commit('setKazangSession', data.newSession)


                        let attemptCount = 0;
                        const maxAttempts = 10;
                        let lastError = null; // To store the last encountered error

                        // Define the recursive function with attempt counting
                        function attemptKazangStep() {
                            attemptCount++;

                            if (attemptCount > maxAttempts) {
                                console.error('Max attempts reached. Stopping retries.');
                                // Return or handle the last encountered error after max attempts
                                return {
                                    Error: lastError || 'Max attempts reached without specific error message.'
                                };
                            }

                            return context.dispatch('secondKazangStep', data)
                                .then((response) => {
                                    if (response.Error) {
                                        console.log(`Error encountered on attempt ${attemptCount}, retrying in 3 seconds...`);
                                        lastError = response.Error; // Capture the current error
                                        setTimeout(attemptKazangStep, 3000); // Retry after 3 seconds
                                    } else {
                                        // Success: process the successful response
                                        console.log('Success:', response);
                                        return response; // Return or further process the successful response
                                    }
                                })
                                .catch((error) => {
                                    // Dispatch error, retry if under max attempts
                                    console.error(`Dispatch error on attempt ${attemptCount}:`, error);
                                    lastError = error.toString(); // Capture the current error as a string
                                    setTimeout(attemptKazangStep, 3000); // Retry after 3 seconds
                                });
                        }

                        // Start the first attempt
                        return attemptKazangStep();
                    }
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err, true)
                })
            }

            return axios.post(kazangApi + `pay/${store.StoreDB}`, {
                session: context.getters.getKazangSessionID,
                amount: Number(amount.toFixed(2)),
                cell: payload
            }).then(({data}) => {
                if (!data.secondStep && data.Response === '0') {
                    return context.dispatch('processKazangInvoice', {
                        amount: amount,
                        splitObj: null,
                        SecondRefrence: data.SecondRefrence
                    })
                } else if (data.Response === '3') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response === '41') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response !== '0') {
                    throw new Error(data.Print)
                } else {
                    data.customerContact = payload;
                    context.commit('setKazangSession', data.newSession)

                    let attemptCount = 0;
                    const maxAttempts = 10;
                    let lastError = null; // To store the last encountered error

                    // Define the recursive function with attempt counting
                    function attemptKazangStep() {
                        attemptCount++;

                        if (attemptCount > maxAttempts) {
                            console.error('Max attempts reached. Stopping retries.');
                            // Return or handle the last encountered error after max attempts
                            return {
                                Error: lastError || 'Max attempts reached without specific error message.'
                            };
                        }

                        return context.dispatch('secondKazangStep', data)
                            .then((response) => {
                                if (response.Error) {
                                    console.log(`Error encountered on attempt ${attemptCount}, retrying in 3 seconds...`);
                                    lastError = response.Error; // Capture the current error
                                    setTimeout(attemptKazangStep, 3000); // Retry after 3 seconds
                                } else {
                                    // Success: process the successful response
                                    console.log('Success:', response);
                                    return response; // Return or further process the successful response
                                }
                            })
                            .catch((error) => {
                                // Dispatch error, retry if under max attempts
                                console.error(`Dispatch error on attempt ${attemptCount}:`, error);
                                lastError = error.toString(); // Capture the current error as a string
                                setTimeout(attemptKazangStep, 3000); // Retry after 3 seconds
                            });
                    }

                    // Start the first attempt
                    return attemptKazangStep();
                }
            }).catch((err) => {
                context.commit('clearKazangSession')
                errorHandler.tomcatError(err, true)
            })
        },

        resetKazangUser: (context, responseCode) => {
            return Swal.fire({
                title: 'Account Update',
                html: `<input type="number" id="login" style="width: 70%" class="swal2-input" placeholder="Kazang Username">
                       <input type="password" id="password" style="width: 70%" class="swal2-input" placeholder="Kazang Password">`,
                confirmButtonText: 'Sign in',
                focusConfirm: false,
                preConfirm: async () => {
                    const login = Swal.getPopup().querySelector('#login').value
                    const password = Swal.getPopup().querySelector('#password').value
                    if (!login || !password) {
                        return Swal.showValidationMessage(`Please enter login and password`)
                    }
                    let obj = {
                        username: login,
                        password: password
                    };
                    let store = await Storage.getDefaultStore();
                    return axios.post(franchiseTomcatApi + `SetKazangUser/${store.StoreDB}`, obj)
                        .then(async () => await Storage.setAdminUser(obj))
                        .catch(err => Swal.showValidationMessage(errorHandler.tomcatError(err, true)))
                }
            }).then(res => {
                if (res.isConfirmed) {
                    let message = responseCode === `3` ? `Account Updated , Please try again` :
                        `Account Updated , Try again in 30 min`;
                    return Swal.fire(
                        `Success!`,
                        message,
                        `success`
                    ).finally(() => {
                        throw new Error(message)
                    });
                }
            });
        },

        firstSpennPaymentStep: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let saleDetails = context.getters.getSaleDetails;
            let amount = Number(saleDetails.total) - Number(context.getters.getSaleDiscount.total);

            if (typeof payload === 'object') {
                return axios.post(kazangApi + `pay/${store.StoreDB}/spennPayment`, {
                    session: context.getters.getKazangSessionID,
                    amount: Number(payload.splitArray.Kazang.toFixed(2)),
                    cell: payload.customerContact
                }).then(({data}) => {
                    if (!data.secondStep && data.Response === '0') {
                        return context.dispatch('processKazangInvoice', {
                            amount: amount,
                            splitObj: payload,
                            SecondRefrence: data.SecondRefrence
                        })
                    } else if (data.Response === '3') {
                        return context.dispatch(`resetKazangUser`, data.Response);
                    } else if (data.Response === '41') {
                        return context.dispatch(`resetKazangUser`, data.Response);
                    } else if (data.Response !== '0') {
                        throw new Error(data.Print)
                    } else {
                        data.customerContact = payload.customerContact;
                        data.splitObj = payload;
                        context.commit('setKazangSession', data.newSession)
                        return data;
                    }
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err)
                })
            }

            return axios.post(kazangApi + `pay/${store.StoreDB}/spennPayment`, {
                session: context.getters.getKazangSessionID,
                amount: Number(amount.toFixed(2)),
                cell: payload
            }).then(({data}) => {
                if (!data.secondStep && data.Response === '0') {
                    return context.dispatch('processKazangInvoice', {
                        amount: amount,
                        splitObj: null,
                        SecondRefrence: data.SecondRefrence
                    })
                } else if (data.Response === '3') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response === '41') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response !== '0') {
                    throw new Error(data.Print)
                } else {
                    data.customerContact = payload;
                    context.commit('setKazangSession', data.newSession)
                    return data;
                }
            }).catch((err) => {
                context.commit('clearKazangSession')
                errorHandler.tomcatError(err, true)
            })
        },

        secondSpennStep: async (context, firstStepResponse) => {
            let store = await Storage.getDefaultStore();
            return axios.post(kazangApi + `pay/${store.StoreDB}/spennApprove`, JSON.parse(firstStepResponse.SecondStepPayload))
                .then(({data}) => {
                    if (!data.Error) {
                        if (!data.approved) return {Error: 'Not Confirmed'};
                        return context.dispatch('processKazangInvoice', {
                            amount: firstStepResponse.amount,
                            splitObj: firstStepResponse.splitObj ? firstStepResponse.splitObj : null,
                            SecondRefrence: data.SecondRefrence
                        })
                    }
                    return data
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err, true)
                })
        },


        secondKazangStep: async (context, firstStepResponse) => {
            let store = await Storage.getDefaultStore();
            let network = await context.dispatch('checkCustomerNetwork', firstStepResponse.customerContact);
            let route = ''

            switch (network) {
                case 'MTN':
                    route = '/mtnApprove';
                    break
                case 'Airtel':
                    route = '/airtelApprove';
                    break
                default:
                    route = '/zamtelApprove'
                    break
            }
            let url = kazangApi + `pay/${store.StoreDB}${route}`;
            return axios.post(url, JSON.parse(firstStepResponse.SecondStepPayload))
                .then(({data}) => {
                    if (!data.Error) {
                        if (!data.approved) {
                            return {
                                Error: 'Not Confirmed'
                            }
                        }
                        return context.dispatch('processKazangInvoice', {
                            amount: firstStepResponse.amount,
                            splitObj: firstStepResponse.splitObj ? firstStepResponse.splitObj : null,
                            SecondRefrence: data.SecondRefrence
                        })
                    }
                    return data
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err)
                })

        },

        processKazangInvoice: (context, response) => {
            if (response.splitObj) {
                response.splitObj.SecondRefrence = response.SecondRefrence;
                return context.dispatch('processTransaction', response.splitObj)
                    .then(() => "processed")
            }
            return context.dispatch('processTransaction', {
                refund: false,
                total: Number(response.amount.toFixed(2)),
                paid: Number(response.amount.toFixed(2)),
                SecondRefrence: response.SecondRefrence,
                change: 0,
                type: "Kazang"
            }).then(() => "processed")
        },

        checkCustomerNetwork(context, phoneNumber) {
            let prefix = phoneNumber.slice(0, 3);
            switch (prefix) {
                case '096':
                case '076':
                    return 'MTN';
                case '097':
                case '077':
                    return 'Airtel'
                default :
                    return 'Zamtel';
            }
        },

        requestPasswordReset: async (context, username) => {
            return axios.post(franchiseTomcatApi+`SetKazangUser/${username}/forgotPasswordRequest`,{
                username : username
            }).catch(err=>errorHandler.tomcatError(err))
        },

        resetPassword: async (context, payload) => {
            return axios.post(franchiseTomcatApi+`SetKazangUser/${payload.username}/resetPassword`,payload)
                .then(()=>router.push({ name : 'login' }))
                .catch(err=>errorHandler.tomcatError(err))
        },

    }
}
