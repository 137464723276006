<template>
  <div class="container-fluid navigation-bar p-1 px-2">

    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box d-flex justify-content-center">
      <div class="d-flex flex-column" v-if="$store.getters.isTot">
        <img src="@/assets/img/caution.png" alt="Tot"
             style="background: black;border-radius: 10px;position: sticky;z-index: 100;margin-right: 50px"
             height="50px" width="50px">
      </div>
    </div>

    <div class="user-settings">
      <Notification/>

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="arrows-rotate"/>
      </button>
    </div>
  </div>
</template>

<script>


import Notification from "@/components/layout/navigation/Notification";

export default {
  name: "NavigationBar",
  components: {Notification},
  props: {
    toggle: {
      type: Function,
      require: true
    },
  },
  methods: {
    syncData() {
      this.$utils.showLoading();
      return window.location.reload(true);
    },
    tawkTo() {
      return window.open('https://tawk.to/LytSupport', '_self');
    }
  }
}
</script>

<style lang="scss" scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
  float: right;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.text-white {
  font-size: 30px;
}
</style>
