<template>
  <div class="sale-details">
    <div class="sale-detail-label">
      <h6>Sale Total({{ getSaleUnits }})</h6>
    </div>
    <div class="sale-detail-value">
      <h4>{{ $utils.formatMoney(saleTotal - discount.total) }}</h4>

    </div>
  </div>
</template>

<script>
export default {
  name: "SaleDetails",
  computed: {
    getSaleUnits() {
      return this.$store.getters.getSaleUnits
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount
    }, discount() {
      return this.$store.getters.getSaleDiscount
    },
  }
}
</script>

<style lang="scss" scoped>
.sale-details {
  display: grid;
  grid-gap: 5px 10px;
  padding: 10px;
  //grid-template-columns: 50% 50%;
  width: 100%;
  grid-template-columns: 35% 65%;

  .sale-detail-label {
    text-align: left;
  }

  .sale-detail-value {
    text-align: right;
    padding-right: 18px;
  }
}

h4, h5, h6 {
  margin: 0;
}

h4 {
  font-weight: 700;
}
</style>
