import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'tax',
    state: {
        taxLabels : [],
    },
    getters: {
        getTaxLabels : (state) => state.taxLabels,
        getZraCategoryByLabel : (state) => (taxLabel) => {
            return state.taxLabels.find(category => category.TaxLabel.toLowerCase() === taxLabel.toLowerCase());
        }
    },
    mutations: {
        setTaxLabels : async (state , payload) => {
            if (payload) await Storage.setTaxLabels(payload);
            else payload = await Storage.getAllTaxLabels();
            state.taxLabels = payload;
        }
    },
    actions: {
        getAllTaxLabels : async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return ;
            return axios.get(backOfficeTomcatApi+`zra_category/${store.StoreDB}/getAll`)
                .then(({data}) => context.commit(`setTaxLabels`, data))
                .catch(err=>errorHandler.tomcatError(err))
        },
    }
}
