<template>
  <div class="page">
    <Header/>
    <div class="container d-flex justify-content-center">
      <div class="card py-3 shadow-lg rounded-3" style="border-radius:0; border:none">

        <div class="card-body p-1">
          <h4 class="card-title mb-3 text-dark" style="font-weight:700">Select <span
              style="color: #9ccc65">Subscription</span> Details</h4>

<!--          <button type="button" class="btn mb-2 btn-primary" @click="setupZero">
            <span class="badge mx-2 bg-info">Free</span>
            <b>ZERO</b>
          </button>-->

          <button type="button" class="btn btn-primary" @click="setupPremium">
            <span class="badge mx-2 bg-warning"><font-awesome-icon icon="fa-solid fa-crown"/></span>
            <b>PREMIUM</b>
          </button>

        </div>

      </div>
    </div>
    <p class="text-center mt-5">Powered By <a target="_blank" href="https://chrilantech.com">Lyt-Soft POS</a></p>
    <p class="text-center mt-5"><a target="_blank" href="https://tawk.to/LytSupport">Contact Support</a></p>
  </div>
</template>

<script>
import Header from "@/components/layout/utils/Header";

export default {
  name: "SelectSubscriptionType",
  components: {Header},
  beforeMount() {
    if (!this.storeDetails){
      return this.$router.back();
    }
  },
  data(){
    return {
      storeDetails : JSON.parse(localStorage.getItem(`tempStoreDetails`))
    }
  },
  methods : {
    setupZero(){
      this.storeDetails.subscriptionType = 1;
      return this.$store.dispatch(`setupStore`,this.storeDetails);
    },

    setupPremium(){
      this.storeDetails.subscriptionType = 4;
      return this.$store.dispatch(`confirmationText`,this.storeDetails);
    },
  }
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  font-weight: 700;
  height: 60px;
  border-radius: 10px;
  max-width: 500px;
}

.card {
  padding-left: 10px;
  padding-right: 10px;
  width: 400px;
  max-width: 95vw;
  margin-top: -3rem !important;
}
</style>
