<template>
  <div>
    <div class="card pt-3" style="border:none">
      <div class="card-body p-1">
        <h4 class="card-title mb-3   text-dark text-uppercase" style="font-weight:700"><span
            style="color: #0092f7">Assign</span> Float</h4>
        <form @submit.prevent="">
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                       v-model="assignedTo" id="floatingTextInput1"
                       placeholder="Cashier" required readonly>
                <label for="floatingTextInput1">Assigned To</label>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="number" step="0.01" class="form-control"
                   v-model="floatAmount" id="floatAmount"
                   placeholder="Amount"
                   required>
            <label for="floatAmount">Float Amount</label>
          </div>
          <div class="text-center submit-btns">
            <button :disabled="loading" class="btn" @click="startShift">
              <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Start Shift
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatAssignForm",
  data() {
    return {
      assignedTo: '',
      floatAmount: 0,
      supervisorPassword: 'Auto',
      allowed: true,
      users: [],
      loading : false ,
    }
  },
  beforeMount() {
    this.checkFloat()
  },
  computed: {
    curUser() {
      return this.$store.getters.getCurrentUser;
    },
    set() {
      return this.$store.getters.getDeviceSettings
    },
  },
  methods: {
    async checkFloat() {
      this.loading = true;
      return this.$store.dispatch('checkUserFloat')
          .then(async (res)=>{
            if (typeof res === 'string'){
              this.assignedTo = res
              return this.$store.dispatch('getAutoFloatByGroup').then(res=>this.floatAmount = res)
            }
          }).finally(() => this.loading = false)
    },

    setUserFloat(user) {
      return this.$store.dispatch('checkFloat', {
        user: user,
        device: this.set.PC
      }).then(() => {
        return this.$store.commit('setUserFloat', {
          user: user,
          device: this.set.PC,
          supervisor: 'Auto',
          float: this.floatAmount
        });
      })
    },

    startShift() {
      this.loading = true;
      this.setFloat()
          .finally(() => this.loading = false);
    },

    setFloat() {
      return this.$store.dispatch('assignFloat', {
        user: this.assignedTo,
        amount: Number(this.floatAmount),
        authorisedBy: this.supervisorPassword,
      }).then((assigned) => {
        if (assigned) return this.$router.push({name: 'pos'});
      })
    },
  }
}
</script>

<style scoped>
.btn {
  background: #1e88e5;
  color: white;
  font-weight: 600;
  display: block;
  height: 45px;
  flex: 1;
}

.submit-btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 400px;
  width: 95vw;
  position: relative;
}
</style>
