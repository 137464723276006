import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'custom-install',
    state: {
        cart: [],
        availableItems: [
            {
                PLU: 'TRAINING',
                Description: `Additional Training`,
                SellingIncl: 1000.00,
                isService : true
            },
            {
                PLU: 'BRCDE-001',
                Description: `Barcode Scanner`,
                SellingIncl: 1795.00,
                isService : false
            },
        ]
    },
    getters: {
        getCart: (state) => state.cart,
        getAvailableItems: (state) => state.availableItems,
    },
    mutations: {
        addToCart: (state, payload) => state.cart.push(payload),
        removeFromCart: (state, itemPLU) => state.cart = state.cart.filter(item => item.PLU !== itemPLU),
    },
    actions: {
        kazangCustomInstallLogin: (context, payload) => {
            return axios.post(franchiseTomcatApi + 'AndroidLogin/Attempt', payload)
                .then(async ({data}) => {
                    await Storage.setAdminUser(payload);
                    if (data.StoreDB && data.StoreName) {
                        return router.push({ name : 'custom-cart' })
                    }else{
                        return responses.showWarning(`Store Not found , Please Sign Up`)
                            .finally(()=>window.open('https://kazang.chrilantech.com','_self'))
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        }
    }
}
