import axios from "axios";
import {Storage} from "@/services/Storage";
import router from "@/router";

export default {
    name: 'tables',
    state: {
        allTables: [],
        isTabOpen: false,
        openTableName : null,
    },
    getters: {
        getAllTables: (state) => state.allTables,
        getIsTabOpen: (state) => state.isTabOpen,
        getOpenTableName : (state) => state.openTableName,
        getTableBySaleNum: (state) => (saleNum) => (state.allTables) ? state.allTables.find(s => s.saleNumber === saleNum) : undefined,
    },

    mutations: {
        setAllTables: (state, payload) => {
            if (payload) {
                return Storage.setTables(payload)
                    .then(() => state.allTables = payload)
            } else {
                return Storage.getAllTables().then(res => state.allTables = (res ? res : []))
            }
        },

        setIsTabOpen: (state, data) => state.isTabOpen = data,
        setOpenTableName: (state, data) => state.openTableName = data,
    },
    actions: {
        getAllTables: async (context) => {
            let loggedIn = await auth.isCashierLoggedIn();
            if (loggedIn) {
                let store = await Storage.getDefaultStore();
                let user = await Storage.getCurrentUser()
                return axios.get(serverApi + `tables/${store.StoreDB}/${user.Username}`)
                    .then(({data}) => {
                        context.commit('setAllTables', data)
                        return data;
                    }).catch((err) => errorHandler.tomcatError(err))
            }
        },
        saveNewTable: async (context, payload) => {
            let settings = await Storage.getDeviceSettings();
            let saleLines = context.getters.getSaleLines;
            for (let i = 0; i < saleLines.length; i++) {
                let line = saleLines[i];
                saleLines[i].Line = (Number(line.Qty) * Number(line.Unit)) - Number(line.Discount);
            }
            let obj = {
                SaleNum: context.getters.getCurrentSale,
                Name: context.getters.getOpenTableName,
                User: context.getters.getCurrentUser.Username,
                PC: settings.PC,
                items: saleLines,
                Customer: payload.Customer,
                discount: parseFloat(context.getters.getSaleDiscount.total),
            }
            return context.dispatch('AddOrUpdateTable', obj)
                .catch(err => {throw new Error(err)});
        },

        createNewTable: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            let settings = await Storage.getDeviceSettings();
            let obj = {
                SaleNum: 0,
                Name: (payload.name && payload.name.length > 0) ? payload.name : "Table #" + context.getters.getCurrentSale,
                User: user.Username,
                PC: settings.PC,
                items: [],
                Customer: payload.Customer,
                discount: 0,
            }
            return context.dispatch('AddOrUpdateTable', obj)
                .then((res) => res)
                .catch(err => errorHandler.tomcatError(err));
        },
        AddOrUpdateTable: async (context, payload) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`, `SaveTable`);
            if (!isAllowed) throw new Error(`User not allowed to Save Tables`);
            let store = await Storage.getDefaultStore();
            return axios.post(serverApi + `tables/${store.StoreDB}`, payload)
                .then(({data}) => {
                    context.commit('clearSale');
                    return data
                }).catch((err) => errorHandler.tomcatError(err))
        },
        updateTable: async (context, payload) => {
            let saleLines = context.getters.getSaleLines;
            let settings = await Storage.getDeviceSettings();
            let user = await Storage.getCurrentUser();
            for (let i = 0; i < saleLines.length; i++) {
                let line = saleLines[i];
                saleLines[i].Line = (Number(line.Qty) * Number(line.Unit)) - Number(line.Discount);
            }
            let data = {
                SaleNum: context.getters.getCurrentSale,
                Name: (payload.name && payload.name.length > 0) ? payload.name : "Table #" + context.getters.getCurrentSale,
                User: user.Username,
                PC: settings.PC,
                items: saleLines,
                Customer: payload.Customer,
                discount: parseFloat(context.getters.getSaleDiscount.total),
            };
            return context.dispatch('AddOrUpdateTable', data)
        },
        removeTable: async (context, sale_number) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`, `VoidAll`);
            if (!isAllowed) return false;
            let store = await Storage.getDefaultStore();
            return axios.delete(serverApi + `tables/${store.StoreDB}/void/${sale_number}`)
                .then(() => true).catch((err) => errorHandler.tomcatError(err))
        },
        getTableData: async (context, saleNumber) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `savetable/${store.StoreDB}/${saleNumber}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getTableBySaleNumber: async (context, saleNumber) => {
            return context.dispatch(`getTableData`, saleNumber).then(res => {
                let tabs = []
                return Promise.all([
                    context.dispatch(`openTable`, res),
                ]).then(() => res)
            }).catch(() => router.push({name: `tables`}))
        },

        getOpenTables: async () => {
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi + `tables/${store.StoreDB}/getAllOpenTables`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        mergeTables: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            payload.mergedBy = user.Username;
            return axios.post(serverApi + `tables/${store.StoreDB}/merge`, payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        transferTable: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            user = user.Username;
            let isAllowed = await context.dispatch(`validateSecurityPermission`, `transferTable`)
            if (!isAllowed) return false
            $utils.showLoading();
            let store = await Storage.getDefaultStore();
            payload.transferredBy = user;
            return axios.put(serverApi + `tables/${store.StoreDB}/transfer`, payload)
                .then(() => true)
                .catch(err => errorHandler.tomcatError(err))
                .finally(() => $utils.hideLoading())
        },
        initiateEmptyTable: async () => {
            let user = await Storage.getCurrentUser();
            user = user.Username;

            let settings = await Storage.getDeviceSettings();
            let pc = settings.PC;
            let store = await Storage.getDefaultStore();
            let storeDb = store.StoreDB;
            let obj = {
                User: user,
                PC: pc,
            }

            return axios.post(serverApi + `tables/${storeDb}/initiateEmptyTable`, obj).then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        async splitTable(context, payload) {
            let store = await Storage.getDefaultStore();
            let storeDb = store.StoreDB;

            let user = await Storage.getCurrentUser();
            user = user.Username;
            let obj = {
                splitBy:user,
                ...payload
            };

            return axios.post(serverApi + `tables/${storeDb}/split`, obj)
                .catch(err => errorHandler.tomcatError(err))
        }
    }
}
